import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dialog'];

  declare readonly dialogTarget: HTMLElement;

  open() {
    this.dialogTarget.classList.add('open');
  }

  close() {
    this.dialogTarget.classList.add('close');
    setTimeout(() => {
      this.dialogTarget.classList.remove('open');
      this.dialogTarget.classList.remove('close');
    }, 300);
  }

  closeSafetyZone(event: Event) {
    event.stopPropagation();
  }

  submit() {
    this.close();
  }
}
