import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tenants', 'owners', 'defaultOwnersTemplate', 'ownersTemplate'];

  declare readonly tenantsTarget: HTMLSelectElement;
  declare readonly ownersTarget: HTMLDivElement;
  declare readonly defaultOwnersTemplateTarget: HTMLTemplateElement;
  declare readonly ownersTemplateTargets: Array<HTMLTemplateElement>;

  connect() {
    this.updateSelect();
  }

  updateSelect() {
    const tenant_id = this.tenantsTarget.value;
    const template = this.findOwnersTemplate(tenant_id);
    const current = this.ownersTarget.firstElementChild;
    if (current) {
      this.ownersTarget.removeChild(current);
    }
    this.ownersTarget.append(template.content.cloneNode(true));
  }

  findOwnersTemplate(tenant_id: string) {
    for (const template of this.ownersTemplateTargets) {
      if (template.dataset.tenantId == tenant_id) {
        console.log('template found');
        return template;
      }
    }
    console.log('template not found');
    console.log(this.defaultOwnersTemplateTarget.innerHTML);
    return this.defaultOwnersTemplateTarget;
  }
}
