import { Controller } from '@hotwired/stimulus';
import BootstrapModalController from '../bootstrap-modal_controller';

export default class extends Controller {
  static targets = ['modal'];
  declare modalTarget: HTMLElement;
  openModal() {
    const modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      'bootstrap-modal',
    ) as BootstrapModalController;
    modalController.open();
  }
}
