import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['list', 'layer'];

  declare readonly listTarget: HTMLInputElement;
  declare readonly layerTarget: HTMLElement;

  openList(event: any) {
    this.listTarget.classList.add('open');
    this.layerTarget.classList.add('active');
    const selectIndex = Number(event.currentTarget.getAttribute('data-index')) + 1;
    const element = document.getElementById('koharu-web__update-talk-member-dialog__join-member');
    const scroll = element?.scrollTop || 0;
    if (element && selectIndex * 57 - scroll + 80 > 224) {
      element.scrollTop = selectIndex * 57 + 100 - 224;
    }
  }

  closeList() {
    this.listTarget.classList.remove('open');
    this.layerTarget.classList.remove('active');
  }

  clickChangeName({ params: { name } }: { params: { name: string } }) {
    this.closeList();
    document.getElementById('koharu-web__update-talk-member-dialog')?.classList?.remove('open');
    document.getElementById('koharu-web__update-talk-member-name-dialog')?.classList?.add('open');
    const targetInput = document.getElementById(
      'koharu-web__update-talk-member-name-dialog__text-field',
    ) as HTMLInputElement;
    targetInput.value = name;
  }

  clickDelete() {
    this.closeList();
    document.getElementById('koharu-web__delete-talk-dialog')?.classList?.add('open');
  }
}
