import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'submit'];

  declare readonly formTarget: HTMLFormElement;
  declare readonly submitTarget: HTMLInputElement;

  connect() {
    this.isAvailableSubmit();
  }

  isAvailableSubmit() {
    if (this.isErrorRequiredCheck(false)) {
      this.submitTarget.disabled = true;
    } else {
      this.submitTarget.disabled = false;
    }
  }

  submitForm(event: Event) {
    // バリデーションチェック
    if (this.isErrorRequiredCheck(true)) {
      event.preventDefault();
    }
  }

  removeError(event: Event) {
    const id = event.target && event.target instanceof Element ? event.target.id : '';
    if (!id) return;

    document.getElementById(id)?.classList?.remove('error');
    const errorMsg = document.getElementById(id + '--error-msg');
    if (errorMsg) {
      errorMsg.innerHTML = '';
      errorMsg.style.display = 'none';
    }
  }

  isErrorRequiredCheck(isShoeError: boolean) {
    let isError = false;
    const requiredFields = this.formTarget.querySelectorAll<HTMLInputElement>('input:required');

    requiredFields.forEach(field => {
      if (!field.disabled && !field.value.trim()) {
        if (isShoeError) {
          field.classList.add('error');
          const errorMsg = document.getElementById(field.id + '--error-msg');
          if (errorMsg) {
            errorMsg.innerHTML = '必須入力です';
            errorMsg.style.display = 'block';
          }
        }
        isError = true;
      }
    });

    const newPassword: HTMLInputElement = document.getElementById('new-password-input') as HTMLInputElement;
    const newPasswordCheck: HTMLInputElement = document.getElementById('new-password-check-input') as HTMLInputElement;

    if (newPassword && newPasswordCheck && newPassword.value !== newPasswordCheck.value) {
      isError = true;
    }

    return isError;
  }
}
