import { Controller } from '@hotwired/stimulus';
import BootstrapModalController from '../bootstrap-modal_controller';

export default class extends Controller {
  static targets = ['modal', 'count'];
  declare modalTarget: HTMLElement;
  declare countTarget: HTMLElement;
  openModal() {
    //var data = event.currentTarget.dataset
    this.countTarget.setAttribute('value', '');
    const modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      'bootstrap-modal',
    ) as BootstrapModalController;
    modalController.open();
  }
}
