import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['link'];
  static values = {
    url: String,
  };

  declare readonly linkTarget: HTMLInputElement;
  declare readonly urlValue: string;

  connect() {
    this.isActiveLink();
  }

  isActiveLink() {
    const pathname = window.location.pathname;

    if (pathname.match(this.urlValue)) {
      this.linkTarget.classList.add('active');
    }

    return false;
  }
}
