import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['list', 'layer'];

  declare readonly listTarget: HTMLInputElement;
  declare readonly layerTarget: HTMLElement;

  openList() {
    this.listTarget.classList.add('open');
    this.layerTarget.classList.add('active');
  }

  closeList() {
    this.listTarget.classList.remove('open');
    this.layerTarget.classList.remove('active');
  }

  clickChangeName() {
    this.closeList();
  }

  clickShowHistory() {
    this.closeList();
  }
}
