import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['nav'];

  declare readonly navTarget: HTMLInputElement;

  connect() {
    // DO NOTHING
  }

  openNav() {
    this.navTarget.classList.add('active');
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }

  closeNav() {
    this.navTarget.classList.add('close');
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setTimeout(() => {
      this.navTarget.classList.remove('active');
      this.navTarget.classList.remove('close');
    }, 300);
  }
}
