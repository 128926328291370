import { Controller } from '@hotwired/stimulus';
import { opera10StackLineParser } from '@sentry/browser';

export default class extends Controller {
  static targets = [
    'tenants',
    'users',
    'toTenantUsers',
    'toTenantUsersJson',
    'owners',
    'defaultOwnersTemplate',
    'ownersTemplate',
  ];

  declare readonly tenantsTarget: HTMLSelectElement;
  declare readonly usersTarget: HTMLSelectElement;
  declare readonly toTenantUsersTarget: HTMLSelectElement;
  declare readonly toTenantUsersJsonTarget: HTMLInputElement; // 選択された送信先のJSONが入る

  declare readonly ownersTarget: HTMLDivElement;
  declare readonly defaultOwnersTemplateTarget: HTMLTemplateElement;
  declare readonly ownersTemplateTargets: Array<HTMLTemplateElement>;

  connect() {
    this.tenantsTarget.selectedIndex = 0;
    this.onChangeTenant();
  }

  onChangeTenant() {
    const tenant_id = this.tenantsTarget.value;
    const template = this.findOwnersTemplate(tenant_id);
    const current = this.ownersTarget.firstElementChild;
    if (current) {
      this.ownersTarget.removeChild(current);
    }
    this.ownersTarget.append(template.content.cloneNode(true));
    this.usersTarget.selectedIndex = 0;
  }

  onAdd() {
    const tenant_value = this.tenantsTarget.value;
    const user_value = this.usersTarget.value;
    const to_value = `${tenant_value},${user_value}`;
    if (this.checkDuplicate(to_value)) return;
    const tenant_text = this.tenantsTarget.options[this.tenantsTarget.selectedIndex].text;
    const user_text = this.usersTarget.options[this.usersTarget.selectedIndex].text;
    const opt = document.createElement('option');
    opt.value = `${tenant_value},${user_value}`;
    opt.text = `${tenant_text}-${user_text}`;
    this.toTenantUsersTarget.add(opt, null);
    this.toTenantUsersTarget.selectedIndex = this.toTenantUsersTarget.length - 1;
  }

  onAddTenant(event: Event) {
    event.preventDefault();
    this.onAdd();
  }

  onAddUser(event: Event) {
    event.preventDefault();
    this.onAdd();
  }

  onRemoveTenantUser(event: Event) {
    event.preventDefault();
    const index = this.toTenantUsersTarget.selectedIndex;
    this.toTenantUsersTarget.remove(index);
    this.toTenantUsersTarget.selectedIndex = index - 1;
  }

  findOwnersTemplate(tenant_id: string) {
    for (const template of this.ownersTemplateTargets) {
      if (template.dataset.tenantId == tenant_id) {
        console.log('template found');
        return template;
      }
    }
    console.log('template not found');
    console.log(this.defaultOwnersTemplateTarget.innerHTML);
    return this.defaultOwnersTemplateTarget;
  }

  checkDuplicate(opt_value: string) {
    // 全テナント/全管理者は空の場合のみ追加可能
    if (opt_value == '-1,-1' && this.toTenantUsersTarget.length > 0) return true;
    const opt_values = opt_value.split(',');
    for (let i = 0; i < this.toTenantUsersTarget.options.length; i++) {
      const toOption = this.toTenantUsersTarget.options[i];
      if (toOption.value == '-1,-1')
        // 全テナント/全管理者があれば、追加は全て不可
        return true;
      if (toOption.value == opt_value)
        // 全く同一のエントリの追加は不可
        return true;
      if (toOption.value == `${opt_values[0]},-1`)
        // ユーザ追加は、同一テナントの全管理者があれば不可
        return true;
      if (opt_values[1] == '-1') {
        // 全管理者追加は、同一テナントのユーザがあれば不可
        const to_option_values = toOption.value.split(',');
        if (to_option_values[0] == opt_values[0]) return true;
      }
    }
    return false;
  }

  updateToTenantUsersJson() {
    // 選択された送信先を、form-post用に、JSON化していれる
    let res = '';
    for (let i = 0; i < this.toTenantUsersTarget.options.length; i++) {
      const toOption = this.toTenantUsersTarget.options[i];
      if (res != '') res += ',';
      res += `[${toOption.value}]`;
    }
    this.toTenantUsersJsonTarget.value = `[${res}]`;
  }
}
