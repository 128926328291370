import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['list', 'layer', 'content'];

  declare readonly listTarget: HTMLInputElement;
  declare readonly layerTarget: HTMLElement;
  declare readonly contentTarget: HTMLElement;

  openList(event: Event) {
    event.stopPropagation();
    this.listTarget.classList.add('open');
    this.layerTarget.classList.add('active');
  }

  closeList(event: Event) {
    event.stopPropagation();
    this.listTarget.classList.remove('open');
    this.layerTarget.classList.remove('active');
  }

  clickChangeTitle(event: Event & { params: { title: string; action: string } }) {
    const { title, action } = event.params;

    event.stopPropagation();
    this.closeList(event);

    const dialog = document.getElementById('koharu-web__update-talk-title-dialog') as HTMLElement;
    dialog.classList.add('open');

    const form = dialog.querySelector('form') as HTMLFormElement;
    form.action = action;

    const targetInput = document.getElementById('koharu-web__update-talk-title-dialog__text-field') as HTMLInputElement;
    targetInput.value = title;
  }

  clickChangeMember(event: Event) {
    event.stopPropagation();
    this.closeList(event);
    document.getElementById('koharu-web__update-talk-member-dialog')?.classList?.add('open');
  }

  clickChangeStartDate(event: Event & { params: { date: string; action: string } }) {
    const { date, action } = event.params;

    event.stopPropagation();
    this.closeList(event);

    const dialog = document.getElementById('koharu-web__update-talk-start-date-dialog') as HTMLElement;
    dialog.classList.add('open');

    const form = dialog.querySelector('form') as HTMLFormElement;
    form.action = action;

    const dateValue = date.slice(0, 10);
    const hourValue = date.slice(11, 13);
    const minuteValue = date.slice(14, 16);

    const dateInput = document.getElementById(
      'koharu-web__update-talk-start-date-dialog__text-field--date',
    ) as HTMLInputElement;
    dateInput.value = dateValue;
    const hourInput = document.getElementById(
      'koharu-web__update-talk-start-date-dialog__text-field--hour',
    ) as HTMLInputElement;
    hourInput.value = hourValue;
    const minuteInput = document.getElementById(
      'koharu-web__update-talk-start-date-dialog__text-field--minute',
    ) as HTMLInputElement;
    minuteInput.value = minuteValue;
  }

  clickDelete(event: Event & { params: { action: string } }) {
    const { action } = event.params;

    event.stopPropagation();
    this.closeList(event);

    const dialog = document.getElementById('koharu-web__delete-talk-dialog') as HTMLElement;
    dialog.classList.add('open');

    const form = dialog.querySelector('form') as HTMLFormElement;
    form.action = action;
  }

  addHoverCss() {
    this.contentTarget.classList.add('actions-hover');
  }

  removeHoverCss() {
    this.contentTarget.classList.remove('actions-hover');
  }
}
