import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['snackbar'];

  declare readonly snackbarTarget: HTMLElement;

  connect() {
    setTimeout(() => this.isShow(), 500);

    if (this.snackbarTarget.classList.contains('long')) {
      setTimeout(() => this.isHide(), 3500);
    } else {
      setTimeout(() => this.isHide(), 2000);
    }
  }

  isShow() {
    this.snackbarTarget.style.display = 'block';
    this.snackbarTarget.classList.add('is-show');
  }

  isHide() {
    const handleTransitionEnd = () => {
      this.snackbarTarget.removeEventListener('transitionend', handleTransitionEnd);
      this.snackbarTarget.style.display = 'none';
    };
    this.snackbarTarget.addEventListener('transitionend', handleTransitionEnd);
    this.snackbarTarget.classList.remove('is-show');
  }
}
