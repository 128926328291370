import { Controller } from '@hotwired/stimulus';
import consumer from 'channels/consumer';
import * as Rails from '@rails/ujs';
import { Mixin, Subscription } from '@rails/actioncable';
import { TalkTimeline } from 'apis';

interface RecieveData {
  talk_timeline?: TalkTimeline;
  talk_timelines?: Array<TalkTimeline>;
}

interface SubscriptionMixin {
  received: (data: RecieveData) => void;
}

export default class extends Controller {
  declare reader: Subscription & Mixin & SubscriptionMixin;

  urlbase() {
    return document.location.href.replace('action_cable_caption_display', '');
  }

  format_time(time?: string) {
    if (!time) return '';
    const t = Date.parse(time);
    return new Intl.DateTimeFormat('ja-jp', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(t);
  }

  card(tag: string, style = '', id: string | null = null) {
    let idattr = '';
    if (id) {
      idattr = `id='${id}'`;
    }
    return `<div style='${style}' ${idattr} class='card card-light p-2 mb-3'>${tag}</div>`;
  }

  latest() {
    const area = document.getElementById('area');
    if (area) {
      area.scrollTop = area.scrollHeight;
    }
  }

  keep_latest() {
    const check = document.getElementById('latest_check');
    return (check as HTMLInputElement).checked;
  }

  clear() {
    const element = document.getElementById('right');
    (element as HTMLElement).innerHTML = '';
  }

  // 追加または更新
  upsert_render_timeline_speech(data: TalkTimeline, id: string, indication: string) {
    const speech = data.speech;
    const spoke_at = speech?.spoke_at;
    const source = speech?.source;
    if (!source) return `<p>Error: ${JSON.stringify(data)}</p>`;
    let name = source.name;
    const ss_id = source.ss_id;
    const angle_az = source.angle_az;
    const icon_color = source.icon_color;
    const icon_border_color = source.icon_border_color;
    const speaker = speech.speaker;
    if (speaker) {
      if (speaker.name) name = speaker.name;
    }

    const tag = this.card(
      `<div>` +
        `<span style='color: ${icon_color}'>●</span>` +
        `<span style='color: ${icon_border_color}'>◯</span>` +
        `${name} <span class='small'>(ss_id: ${ss_id} angle_az:${angle_az}) (${this.format_time(
          spoke_at,
        )})</span></div>` +
        `<div>${speech.content}&nbsp;&nbsp;` +
        `${speech.translation_content ?? ''}</div>`,
      '',
      speech.uuid,
    ); // 次回以降のためにuuid埋め込んでおく
    // タグの更新または追加
    const existing_element = document.getElementById(speech['uuid']);
    if (existing_element) {
      existing_element.outerHTML = tag;
    } else if (indication == 'add_bottom') {
      const element = document.getElementById(id);
      if (element) {
        const rendered = element.innerHTML;
        element.innerHTML = rendered + tag;
      }
    } else {
      const element = document.getElementById(id);
      if (element) {
        const rendered = element.innerHTML;
        element.innerHTML = tag + rendered;
      }
    }
  }

  // 追加または更新
  upsert_render_timeline_post(data: TalkTimeline, id: string, indication: string) {
    const post = data.post;
    const posted_at = post?.posted_at;
    const poster = post?.poster;
    if (!poster) return `<p>Error: ${JSON.stringify(data)}</p>`;
    let name = poster.name;
    let icon_color = poster.icon_color;
    let icon_border_color = poster.icon_border_color;
    const speaker = post.speaker;
    if (speaker) {
      if (speaker.name) name = speaker.name;
      if (speaker.icon_color) icon_color = speaker.icon_color;
      if (speaker.icon_border_color) icon_border_color = speaker.icon_border_color;
    }
    const tag = this.card(
      `<div>` +
        `<span style='color: ${icon_color}'>●</span>` +
        `<span style='color: ${icon_border_color}'>◯</span>` +
        `${name} <span class='small'>(${this.format_time(posted_at)})</span></div>` +
        `<div>${post.content}</div>`,
      //`<div>${post.content}&nbsp;&nbsp;` +
      //`${post.translation_content}</div>`, // translation_contentが未定義
      'background: #ffffe0',
      post.id ? `post-${post.id}` : null,
    ); // 次回以降のためにuuid埋め込んでおく
    // タグの更新または追加
    const existing_element = document.getElementById(`post-${data.uuid}`);
    if (existing_element) {
      existing_element.outerHTML = tag;
    } else if (indication == 'add_bottom') {
      const element = document.getElementById(id);
      if (element) {
        const rendered = element.innerHTML;
        element.innerHTML = rendered + tag;
      }
    } else {
      const element = document.getElementById(id);
      if (element) {
        const rendered = element.innerHTML;
        element.innerHTML = tag + rendered;
      }
    }
  }

  render_right(data: TalkTimeline, indication: string) {
    if (
      data.timeline_type == 2 ||
      data.timeline_type == 3 // speech,post
    ) {
      if (data.timeline_type == 2 && data.speech) {
        // 新しいspeechを追加, すでにあるspeechは中身を更新(domに埋め込んだuuidで判断)
        this.upsert_render_timeline_speech(data, 'right', indication);
      } else if (data.timeline_type == 3 && data.post) {
        // 新しいpostを追加, すでにあるpostは中身を更新(domに埋め込んだidで判断)
        this.upsert_render_timeline_post(data, 'right', indication);
      }
    }
  }

  connect() {
    this.reader = consumer.subscriptions.create('CaptionDisplayReaderChannel', {
      received: (data: RecieveData) => {
        // console.log(JSON.stringify(data));
        if (data.talk_timeline) {
          this.render_right(data.talk_timeline, 'add_bottom');
          if (this.keep_latest()) this.latest();
        } else if (data.talk_timelines) {
          for (const talk_timeline of data.talk_timelines) {
            this.render_right(talk_timeline, 'add_bottom');
          }
          if (this.keep_latest()) this.latest();
        }
      },
    });
  }
}
