import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['dialog', 'form', 'textField', 'submit'];

  declare readonly dialogTarget: HTMLElement;
  declare readonly formTarget: HTMLFormElement;
  declare readonly textFieldTarget: HTMLInputElement;
  declare readonly submitTarget: HTMLInputElement;

  open() {
    this.dialogTarget.classList.add('open');
  }

  close() {
    this.dialogTarget.classList.add('close');
    setTimeout(() => {
      this.dialogTarget.classList.remove('open');
      this.dialogTarget.classList.remove('close');
    }, 300);
  }

  closeSafetyZone(event: Event) {
    event.stopPropagation();
  }

  submit() {
    this.close();
    Rails.fire(this.formTarget, 'submit');
  }

  isAvailableSubmit() {
    if (this.isErrorRequiredCheck(false)) {
      this.submitTarget.disabled = true;
    } else {
      this.submitTarget.disabled = false;
    }
  }

  submitForm(event: Event) {
    // バリデーションチェック
    if (this.isErrorRequiredCheck(true)) {
      event.preventDefault();
    }
  }

  removeError(event: Event) {
    const id = event.target && event.target instanceof Element ? event.target.id : '';
    if (!id) return;

    document.getElementById(id)?.classList?.remove('error');
    const errorMsg = document.getElementById(id + '--error-msg');
    if (errorMsg) {
      errorMsg.innerHTML = '';
      errorMsg.style.display = 'none';
    }
  }

  isErrorRequiredCheck(isShowError: boolean) {
    let isError = false;
    const requiredFields = this.formTarget.querySelectorAll<HTMLInputElement>('input:required');

    requiredFields.forEach(field => {
      if (!field.disabled && !field.value.trim()) {
        if (isShowError) {
          field.classList.add('error');
          const errorMsg = document.getElementById(field.id + '--error-msg');
          if (errorMsg) {
            errorMsg.innerHTML = '必須入力です';
            errorMsg.style.display = 'block';
          }
        }
        isError = true;
      }
    });

    return isError;
  }
}
