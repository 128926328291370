import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'checkbox', 'label'];

  declare readonly inputTarget: HTMLInputElement;
  declare readonly checkboxTarget: HTMLInputElement;
  declare readonly labelTarget: HTMLElement;

  connect() {
    this.update();
  }

  update() {
    if (this.checkboxTarget.checked) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.inputTarget.type = 'text';
    this.labelTarget.classList.add('visibility-icon');
    this.labelTarget.classList.remove('visibilityoff-icon');
  }

  hide() {
    this.inputTarget.type = 'password';
    this.labelTarget.classList.remove('visibility-icon');
    this.labelTarget.classList.add('visibilityoff-icon');
  }
}
