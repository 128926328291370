import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  changeFileName(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) return;
    const fileName = event.target.value.replace(/^.*[\\/]/, '');
    const customFileInput = event.target.parentNode as HTMLElement;
    const labelElm = customFileInput.querySelector('label');
    if (labelElm) {
      labelElm.textContent = fileName;
    }
  }
}
