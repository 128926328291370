import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['ownerInput', 'ownerIcon', 'memberInput', 'memberIcon'];

  declare readonly ownerInputTarget: HTMLInputElement;
  declare readonly ownerIconTarget: HTMLElement;
  declare readonly memberInputTarget: HTMLInputElement;
  declare readonly memberIconTarget: HTMLElement;

  connect() {
    this.update();
  }

  update() {
    if (this.ownerInputTarget.checked) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.ownerIconTarget.classList.add('checked');
    this.memberIconTarget.classList.remove('checked');
  }

  hide() {
    this.ownerIconTarget.classList.remove('checked');
    this.memberIconTarget.classList.add('checked');
  }
}
