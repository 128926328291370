import { Controller } from '@hotwired/stimulus';
import consumer from 'channels/consumer';
import * as Rails from '@rails/ujs';
import { Mixin, Subscription } from '@rails/actioncable';
import { TalkMember, TalkTimeline } from 'apis';

interface Typing {
  name: string;
  poster_id: number;
}

interface RecieveData {
  talk_timeline?: TalkTimeline;
  typing?: Typing;
}

interface SubscriptionMixin {
  received: (data: RecieveData) => void;
  typing: () => void;
}

export default class extends Controller {
  declare reader: Subscription & Mixin & SubscriptionMixin;
  declare next_page_token?: string | null;
  declare page_loading: boolean;

  initialize() {
    this.next_page_token = null;
    this.page_loading = true;
  }

  urlbase() {
    return document.location.href.replace('action_cable', '');
  }

  format_time(time?: string) {
    if (!time) return '';
    const t = Date.parse(time);
    return new Intl.DateTimeFormat('ja-jp', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(t);
  }

  card(tag: string, style = '', id: string | null = null) {
    let idattr = '';
    if (id) {
      idattr = `id='${id}'`;
    }
    return `<div style='${style}' ${idattr} class='card card-light p-2 mb-3'>${tag}</div>`;
  }

  bookmark(event: MouseEvent) {
    if (!(event.currentTarget instanceof HTMLElement)) return;

    const uuid = event.currentTarget.dataset.uuid;
    const bookmark = event.currentTarget.dataset.bookmark;
    if (bookmark == 'true') {
      Rails.ajax({
        url: `${this.urlbase()}/speeches/${uuid}/bookmark`,
        type: 'DELETE',
        dataType: 'json',
        success: response => undefined,
        error: (_response, _status, _xhr) => {
          console.error('fetch_members error');
        },
      });
    } else {
      Rails.ajax({
        url: `${this.urlbase()}/speeches/${uuid}/bookmark`,
        type: 'POST',
        dataType: 'json',
        success: response => undefined,
        error: (_response, _status, _xhr) => {
          console.error('fetch_members error');
        },
      });
    }
  }

  fetch_members() {
    Rails.ajax({
      url: `${this.urlbase()}`,
      type: 'GET',
      dataType: 'json',
      success: response => {
        const left = document.getElementById('left');
        if (left) {
          left.innerHTML = this.render_members(response['members']);
        }
      },
      error: (_response, _status, _xhr) => {
        console.error('fetch_members error');
      },
    });
  }

  fetch_timelines() {
    if (!this.page_loading) return;
    const page_token_part = this.next_page_token !== null ? `&page_token=${this.next_page_token}` : '';
    const url = `${this.urlbase()}/timelines?limit=30${page_token_part}`;
    Rails.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: response => {
        this.next_page_token = response['next_page_token'];
        if (this.next_page_token === null) this.page_loading = false;
        response['timelines']?.forEach((data: TalkTimeline) => {
          this.render_right(data, 'add_top');
        });
      },
      error: (_response, _status, _xhr) => {
        console.error('fetch_timelines error');
      },
    });
  }

  latest() {
    const area = document.getElementById('area');
    if (area) {
      area.scrollTop = area.scrollHeight;
    }
  }

  keep_latest() {
    const check = document.getElementById('latest_check');
    return (check as HTMLInputElement).checked;
  }

  reload() {
    const [left, right] = [document.getElementById('left'), document.getElementById('right')];
    if (left) left.innerHTML = '';
    if (right) right.innerHTML = '';
    this.fetch_members();
    this.next_page_token = null;
    this.page_loading = true;
    this.fetch_timelines();
  }

  fetch() {
    this.fetch_timelines();
  }

  // 追加または更新
  upsert_render_timeline_speech(data: TalkTimeline, id: string, indication: string) {
    const speech = data.speech;
    const spoke_at = speech?.spoke_at;
    const source = speech?.source;
    if (!source) return `<p>Error: ${JSON.stringify(data)}</p>`;
    let name = source.name;
    const ss_id = source.ss_id;
    const angle_az = source.angle_az;
    const icon_color = source.icon_color;
    const icon_border_color = source.icon_border_color;
    const speaker = speech.speaker;
    if (speaker) {
      if (speaker.name) name = speaker.name;
    }

    const bookmark = speech.bookmark;
    const tag = this.card(
      `<div>` +
        `<span style='color: ${icon_color}'>●</span>` +
        `<span style='color: ${icon_border_color}'>◯</span>` +
        `${name} <span class='small'>(ss_id: ${ss_id} angle_az:${angle_az}) (${this.format_time(spoke_at)})</span>` +
        `&nbsp;&nbsp;<span style='cursor: pointer;' data-uuid='${
          speech['uuid']
        }' data-bookmark=${bookmark} data-action='click->channels--reader#bookmark' class='small'>[${
          bookmark ? 'ブックマーク解除' : 'ブックマーク'
        }]</span></div>` +
        `<div>${speech.content}</div>`,
      bookmark ? 'font-weight: bold' : '',
      speech.uuid,
    ); // 次回以降のためにuuid埋め込んでおく
    // タグの更新または追加
    const existing_element = document.getElementById(speech['uuid']);
    if (existing_element) {
      existing_element.outerHTML = tag;
    } else if (indication == 'add_bottom') {
      const element = document.getElementById(id);
      if (element) {
        const rendered = element.innerHTML;
        element.innerHTML = rendered + tag;
      }
    } else {
      const element = document.getElementById(id);
      if (element) {
        const rendered = element.innerHTML;
        element.innerHTML = tag + rendered;
      }
    }
  }

  // 追加または更新
  upsert_render_timeline_post(data: TalkTimeline, id: string, indication: string) {
    const post = data.post;
    const posted_at = post?.posted_at;
    const poster = post?.poster;
    if (!poster) return `<p>Error: ${JSON.stringify(data)}</p>`;
    let name = poster.name;
    let icon_color = poster.icon_color;
    let icon_border_color = poster.icon_border_color;
    const speaker = post.speaker;
    if (speaker) {
      if (speaker.name) name = speaker.name;
      if (speaker.icon_color) icon_color = speaker.icon_color;
      if (speaker.icon_border_color) icon_border_color = speaker.icon_border_color;
    }
    const tag = this.card(
      `<div>` +
        `<span style='color: ${icon_color}'>●</span>` +
        `<span style='color: ${icon_border_color}'>◯</span>` +
        `${name} <span class='small'>(${this.format_time(posted_at)})</span></div>` +
        `<div>${post.content}</div>`,
      'background: #ffffe0',
      post.id ? `post-${post.id}` : null,
    ); // 次回以降のためにuuid埋め込んでおく
    // タグの更新または追加
    const existing_element = document.getElementById(`post-${post.id}`);
    if (existing_element) {
      existing_element.outerHTML = tag;
    } else if (indication == 'add_bottom') {
      const element = document.getElementById(id);
      if (element) {
        const rendered = element.innerHTML;
        element.innerHTML = rendered + tag;
      }
    } else {
      const element = document.getElementById(id);
      if (element) {
        const rendered = element.innerHTML;
        element.innerHTML = tag + rendered;
      }
    }
  }

  // 下に追加
  insert_render_timeline_progress(data: TalkTimeline, id: string, indication: string) {
    const progress = data.progress;
    const at = progress?.at;
    let tag = '';
    if (progress?.progress_type == 2) {
      // started
      tag = this.card(`<div>トークを開始しました <span class='small'>(${this.format_time(at)})</span></div>`);
    } else if (progress?.progress_type == 3) {
      // finished
      tag = this.card(`<div>トークを終了しました <span class='small'>(${this.format_time(at)})</span></div>`);
    } else if (progress?.progress_type == 5) {
      // title_update
      tag = this.card(
        `<div>タイトルを変更しました: "${progress.data?.title}" <span class='small'>(${this.format_time(
          at,
        )})</span></div>`,
      );
    } else if (progress?.progress_type == 6) {
      // remind_to_finish
      tag = this.card(
        `<div>まもなくトークが自動終了します: 自動終了時刻:"${this.format_time(
          progress.data?.time_to_finish,
        )}" <span class='small'>(${this.format_time(at)})</span></div>`,
      );
    }
    if (indication == 'add_bottom') {
      const element = document.getElementById(id);
      if (element) {
        const rendered = element.innerHTML;
        element.innerHTML = rendered + tag;
      }
    } else {
      const element = document.getElementById(id);
      if (element) {
        const rendered = element.innerHTML;
        element.innerHTML = tag + rendered;
      }
    }
  }

  render_member(member: TalkMember) {
    const name = member.name;
    const icon_color = member.icon_color;
    const icon_border_color = member.icon_border_color;
    let tags = '';
    member['sources']?.forEach(source => {
      tags +=
        `<div>` +
        `<span style='color: ${source.icon_color}'>●</span>` +
        `<span style='color: ${source.icon_border_color}'>◯</span>` +
        `${source['name']}` +
        `<div class='ml-2'>(ss_id: ${source.ss_id} angle_az:${source.angle_az})</div></div>`;
    });
    member['posters']?.forEach(poster => {
      tags +=
        `<div>` +
        `<span style='color: ${poster.icon_color}'>●</span>` +
        `<span style='color: ${poster.icon_border_color}'>◯</span>` +
        `${poster.name}` +
        `</div>`;
    });
    return this.card(
      `<div>` +
        `<span style='color: ${icon_color}'>●</span>` +
        `<span style='color: ${icon_border_color}'>◯</span>` +
        `${name}</div><div class='ml-2 small'>` +
        tags +
        '</div>',
    );
  }

  render_members(members: TalkMember[]) {
    if (!members) return `<p>Error: render_members</p>`;
    let membersTag = '';
    members.forEach(member => {
      membersTag += this.render_member(member);
    });
    return membersTag;
  }

  render_left(data: TalkTimeline) {
    // progress(member_updateのみ)
    if (data.timeline_type == 1 && data.progress?.progress_type == 4) {
      // 常に中身を全部入れ替え
      const left = document.getElementById('left');
      if (left) {
        left.innerHTML = this.render_members(data.progress?.data?.members ?? []);
      }
    }
  }

  render_right(data: TalkTimeline, indication: string) {
    if (
      data.timeline_type == 2 ||
      data.timeline_type == 3 || // speech,postかprogress(member_update以外)
      (data.timeline_type == 1 && data.progress && data.progress.progress_type != 4)
    ) {
      if (data.timeline_type == 2 && data.speech) {
        // 新しいspeechを追加, すでにあるspeechは中身を更新(domに埋め込んだuuidで判断)
        this.upsert_render_timeline_speech(data, 'right', indication);
      } else if (data.timeline_type == 3 && data.post) {
        // 新しいpostを追加, すでにあるpostは中身を更新(domに埋め込んだidで判断)
        this.upsert_render_timeline_post(data, 'right', indication);
      } else if (data.timeline_type == 1 && data.progress) {
        // 常に下に追加
        this.insert_render_timeline_progress(data, 'right', indication);
      }
    }
  }

  render_typing(data: Typing) {
    // var label = `${data['name']} (POSTER ID:${data['poster_id']})`
    // document.getElementById('typing').innerHTML = `Typing now: ${label}`
    // setTimeout(() => {document.getElementById('typing').innerHTML = ''}, 1000)
  }

  typing() {
    this.reader.typing();
  }

  connect() {
    this.reader = consumer.subscriptions.create('ReaderChannel', {
      received: (data: RecieveData) => {
        // console.log(JSON.stringify(data));
        if (data.talk_timeline) {
          if (!document.getElementById('left')) return; // for admin
          this.render_left(data.talk_timeline);
          this.render_right(data.talk_timeline, 'add_bottom');
          if (this.keep_latest()) this.latest();
        } else if (data.typing) {
          this.render_typing(data.typing);
        }
      },
      typing() {
        this.perform('typing');
      },
    });

    this.reload();
  }
}
