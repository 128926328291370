import { Controller } from '@hotwired/stimulus';
import { format } from 'path';

export default class extends Controller {
  static targets = ['list', 'layer'];

  declare readonly listTarget: HTMLInputElement;
  declare readonly layerTarget: HTMLElement;

  openList() {
    this.listTarget.classList.add('open');
    this.layerTarget.classList.add('active');
  }

  closeList() {
    this.listTarget.classList.remove('open');
    this.layerTarget.classList.remove('active');
  }

  clickReInvitation() {
    this.closeList();
  }

  clickDeleteInvitation({ params: { id } }: { params: { id: string } }) {
    this.closeList();
    document.getElementById('koharu-web__delete-invitation-dialog')?.classList?.add('open');
    const form = document.getElementById('delete-invitation-dialog-form') as HTMLFormElement;
    form.action = form.action.replace(':id', id);
  }
}
