import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['dialog', 'form', 'inputDate', 'submit'];

  declare readonly dialogTarget: HTMLElement;
  declare readonly formTarget: HTMLFormElement;
  declare readonly inputDateTarget: HTMLInputElement;
  declare readonly submitTarget: HTMLInputElement;

  connect() {
    flatpickr(this.inputDateTarget, {
      locale: 'ja',
      dateFormat: 'Y/m/d',
    });
  }

  open() {
    this.dialogTarget.classList.add('open');
  }

  close() {
    this.dialogTarget.classList.add('close');
    setTimeout(() => {
      this.dialogTarget.classList.remove('open');
      this.dialogTarget.classList.remove('close');
    }, 300);
  }

  closeSafetyZone(event: Event) {
    event.stopPropagation();
  }

  submit() {
    this.close();
    Rails.fire(this.formTarget, 'submit');
  }

  isAvailableSubmit() {
    if (this.isErrorRequiredCheck(false)) {
      this.submitTarget.disabled = true;
    } else {
      this.submitTarget.disabled = false;
    }
  }

  submitForm(event: Event) {
    // バリデーションチェック
    if (this.isErrorRequiredCheck(true)) {
      event.preventDefault();
    }
  }

  removeError(event: Event) {
    const id = event.target && event.target instanceof Element ? event.target.id : '';
    if (!id) return;

    document.getElementById(id)?.classList?.remove('error');
    const errorMsg = document.getElementById(id + '--error-msg');
    if (errorMsg) {
      errorMsg.innerHTML = '';
      errorMsg.style.display = 'none';
    }
  }

  numCheck() {
    const date = document.getElementById(
      'koharu-web__update-talk-start-date-dialog__text-field--date',
    ) as HTMLInputElement;
    const hour = document.getElementById(
      'koharu-web__update-talk-start-date-dialog__text-field--hour',
    ) as HTMLInputElement;
    const minute = document.getElementById(
      'koharu-web__update-talk-start-date-dialog__text-field--minute',
    ) as HTMLInputElement;

    function isAvailableTime(time: string) {
      const targetTime = Number(time);

      if (isNaN(targetTime) || targetTime < 0 || 59 < targetTime) {
        return false;
      }

      return true;
    }

    function isAvailableDate() {
      return isNaN(new Date(`${date.value} ${hour.value}:${minute.value}`).getDate());
    }

    if (isAvailableTime(hour.value) && isAvailableTime(minute.value) && isAvailableDate()) {
      return true;
    }

    return false;
  }

  isErrorRequiredCheck(isShowError: boolean) {
    let isError = false;
    const requiredFields = this.formTarget.querySelectorAll<HTMLInputElement>('input:required');

    requiredFields.forEach(field => {
      if (!field.disabled && !field.value.trim() && !this.numCheck()) {
        if (isShowError) {
          field.classList.add('error');
          const errorMsg = document.getElementById(field.id + '--error-msg');
          if (errorMsg) {
            errorMsg.innerHTML = '必須入力です';
            errorMsg.style.display = 'block';
          }
        }
        isError = true;
      }
    });

    return isError;
  }
}
