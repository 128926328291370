import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'submit'];

  declare readonly formTarget: HTMLFormElement;
  declare readonly submitTarget: HTMLInputElement;

  connect() {
    this.isAvailableSubmit();
  }

  isAvailableSubmit() {
    if (this.isErrorRequiredCheck()) {
      this.submitTarget.disabled = true;
    } else {
      this.submitTarget.disabled = false;
    }
  }

  submitForm(event: Event) {
    // バリデーションチェック
    if (this.isErrorRequiredCheck()) {
      event.preventDefault();
    }
  }

  isErrorRequiredCheck() {
    let isError = false;
    const requiredFields = this.formTarget.querySelectorAll<HTMLTextAreaElement>('textarea:required');

    requiredFields.forEach(field => {
      if (!field.disabled && !field.value.trim()) {
        isError = true;
      }
    });

    return isError;
  }
}
