import { Controller } from '@hotwired/stimulus';
import { opera10StackLineParser } from '@sentry/browser';

export default class extends Controller {
  static targets = ['customerTenantUsers', 'toTenantUsers', 'toTenantUsersJson'];

  declare readonly customerTenantUsersTarget: HTMLSelectElement;
  declare readonly toTenantUsersTarget: HTMLSelectElement;
  declare readonly toTenantUsersJsonTarget: HTMLInputElement; // 選択された送信先のJSONが入る

  connect() {
    this.customerTenantUsersTarget.selectedIndex = 0;
  }

  onAddUser(event: Event) {
    event.preventDefault();
    const user_value = this.customerTenantUsersTarget.value;
    if (this.checkDuplicate(user_value)) return;
    const user_text = this.customerTenantUsersTarget.options[this.customerTenantUsersTarget.selectedIndex].text;
    const opt = document.createElement('option');
    opt.value = `${user_value}`;
    opt.text = `${user_text}`;
    this.toTenantUsersTarget.add(opt, null);
    this.toTenantUsersTarget.selectedIndex = this.toTenantUsersTarget.length - 1;
  }

  onRemoveUser(event: Event) {
    event.preventDefault();
    const index = this.toTenantUsersTarget.selectedIndex;
    this.toTenantUsersTarget.remove(index);
    this.toTenantUsersTarget.selectedIndex = index - 1;
  }

  checkDuplicate(opt_value: string) {
    const opt_values = opt_value.split(',');
    for (let i = 0; i < this.toTenantUsersTarget.options.length; i++) {
      const toOption = this.toTenantUsersTarget.options[i];
      if (toOption.value == opt_value)
        // 全く同一のエントリの追加は不可
        return true;
    }
    return false;
  }

  updateToTenantUsersJson() {
    // 選択された送信先を、form-post用に、JSON化していれる
    let res = '';
    for (let i = 0; i < this.toTenantUsersTarget.options.length; i++) {
      const toOption = this.toTenantUsersTarget.options[i];
      if (res != '') res += ',';
      res += `[${toOption.value}]`;
    }
    this.toTenantUsersJsonTarget.value = `[${res}]`;
  }
}
